/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
	UilCalendarAlt,
	UilSearch,
	UilFilterSlash,
} from '@iconscout/react-unicons';

import {
	Body,
	Container,
	Header,
	HeaderButton,
	/*HeaderExport,
	HeaderExportText,*/
	HeaderSearchContainer,
	HeaderSearchInput,
	HeaderTitle,
	Main,
	SearchSelector,
	SelectItem,
	SelectMenu,
	Skeleton,
	ActionButton,
	BodyTR,
	Table,
	TableContainer,
	TBody,
	Th,
	THead,
	HeaderContainer,
} from './style';
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import ModalNovoUsuario from './modalNovoUsuario';
import api from '../../services/api';
import { useEffect } from 'react';
import { Usuario } from '../../models/model';
import { useHistory } from 'react-router-dom';

import ArrowDownWhite from '../../assets/images/arrow_dropdown-white.svg';
import ArrowRightWhite from '../../assets/images/arrow_right-white.svg';
import ArrowLeftWhite from '../../assets/images/arrow_left-white.svg';
import SearchGray from '../../assets/images/search-gray.svg';
import { useRef } from 'react';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';

import EditGray from '../../assets/images/edit-gray.svg';
import RemoveGray from '../../assets/images/delete_outline-gray.svg';
import HeaderUserButton from '../../components/logOut';
import DeleteModal from '../../components/deleteModal';
import { DebouncerInput } from '../../components/Input';
export default function Usuarios() {
	const [searchActive, setSearchActive] = useState(false);
	const [apelido, setApelido] = useState('');
	const [searchInput, setSearchInput] = useState('');

	const [situacao, setSituacao] = useState({ label: '', value: '' });
	const [permissao, setPermissao] = useState({ label: '', value: '' });

	const situacaoRef = useRef<any>(null);
	const [situacaoDropdown, setSituacaoDropdown] = useState(false);

	const permissaoRef = useRef<any>(null);
	const [permissaoDropdown, setPermissaoDropdown] = useState(false);

	const tipos = [
		{ value: 999, label: 'Administrador' },
		{ value: 1, label: 'Consultor' },
	];
	useOutsideAlert(permissaoRef, handleClickOutsidePermissao);
	useOutsideAlert(situacaoRef, handleClickOutsideSituacao);

	function handleClickOutsidePermissao(e: any) {
		if (
			permissaoRef.current &&
			permissaoRef.current !== null &&
			!permissaoRef.current.contains(e.target)
		) {
			setPermissaoDropdown(false);
		}
	}

	function handleClickOutsideSituacao(e: any) {
		if (
			situacaoRef.current &&
			situacaoRef.current !== null &&
			!situacaoRef.current.contains(e.target)
		) {
			setSituacaoDropdown(false);
		}
	}

	const situacoes = [
		//{ value: '', label: '' },
		{ value: 0, label: 'Inativo' },
		{ value: 1, label: 'Ativo' },
	];

	const estadoList = [
		{ label: 'Todos', value: '' },
		{ label: 'Ativo', value: '1' },
		{ label: 'Inativo', value: '0' },
	];

	const [modalVisible, setModalVisible] = useState(false);

	const [usuarios, setUsuarios] = useState<Usuario[]>([]);

	const [loading, setLoading] = useState(false);

	function updateUsuario(UsuarioUpdate: Usuario) {
		const lista = [...usuarios];
		const findIndex = lista.findIndex(
			(l) => l.id === (UsuarioUpdate.id as number)
		);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista[findIndex] = UsuarioUpdate;
		} else {
			lista.push(UsuarioUpdate);
		}

		setUsuarios([...lista]);
	}

	function DeletarUsuario(usuarioId: number) {
		//setEmpresas([...Empresas]);
		const lista = [...usuarios];
		const findIndex = lista.findIndex((l) => l.id === usuarioId);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista.splice(findIndex, 1);
		}

		setUsuarios([...lista]);
	}

	async function getUsuarios() {
		setLoading(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const stat = situacao.value !== '' ? `&status=${situacao.value}` : ``;

		try {
			const response = await api.get(
				`/admin/api/user?type=${permissao.value}${stat}&s=${apelido}`,
				config
			);
			if (response.data.users) {
				setUsuarios(response.data.users);
			}
			setLoading(false);
		} catch (error:any) {
			console.error(error);
			setLoading(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	const history = useHistory();
	useEffect(() => {
		getUsuarios();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apelido, situacao, permissao]);

	useEffect(() => {
		const login = localStorage.getItem('@bill-login');
		if (login) {
			const l = JSON.parse(login);
			if (!(l && l.type === 999)) {
				history.replace('/');
			}
		} else {
			history.replace('/');
		}
	}, []);
	return (
		<Container>
			<Main>
				<Header>
					<HeaderTitle>Usuários</HeaderTitle>
					<HeaderContainer>
						<HeaderButton
							style={{ marginRight: 10 }}
							onClick={(e) => {
								e.preventDefault();
								//setModalNovoDispositivo(true);

								//setModalNovaEmpresa(true);
								setModalVisible(true);
							}}>
							<p>Novo Usuário</p>
						</HeaderButton>
						<HeaderUserButton />
					</HeaderContainer>
					<Modal
						show={modalVisible}
						onHide={() => {
							setModalVisible(false);
						}}>
						<Modal.Body>
							<ModalNovoUsuario
								updateUsuario={updateUsuario}
								setModalVisible={setModalVisible}
							/>
						</Modal.Body>
					</Modal>
				</Header>

				<Body>
					<div className='body_header'>
						<DebouncerInput
							placeholder='Buscar por Nome...'
							setSearchItem={setApelido}
						/>
						<div className='flex-row-center'>
							<SearchSelector
								onClick={(e) => {
									e.preventDefault();
									setSituacao({ value: '', label: 'Todas as situações' });
									setPermissao({ value: '', label: 'Todas as empresas' });
									setApelido('');
									setSearchInput('');
								}}
								active={
									apelido === '' &&
									situacao.value === '' &&
									permissao.value === ''
								}>
								TUDO
							</SearchSelector>
							<Dropdown ref={situacaoRef} show={situacaoDropdown}>
								<Dropdown.Toggle
									style={{ padding: 0 }}
									className='dropdown-toggle-categoria'
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setSituacaoDropdown(!situacaoDropdown);
									}}>
									<SearchSelector
										pressed={situacaoDropdown}
										onClick={(e) => {
											e.preventDefault();
										}}
										active={situacao.value !== ''}>
										{situacao.value === '' ? 'SITUAÇÃO' : situacao.label}
										{situacao.value !== '' && (
											<img
												className='ExportArrowDown'
												alt=''
												src={ArrowDownWhite}
											/>
										)}
									</SearchSelector>
								</Dropdown.Toggle>
								<DropdownMenu
									style={{
										background: 'transparent',
										border: 0,
										marginTop: -10,
									}}>
									<SelectMenu>
										<Dropdown.Item
											style={{ padding: 0 }}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setSituacao({ value: '', label: 'Todas as situações' });
												setSituacaoDropdown(false);
											}}>
											<SelectItem active={situacao.value === ''}>
												{'Todos'}
											</SelectItem>
										</Dropdown.Item>
										{situacoes.map((e) => {
											return (
												<Dropdown.Item
													style={{ padding: 0 }}
													//className='dropdown-item-categoria'
													onClick={(a) => {
														a.preventDefault();
														a.stopPropagation();
														setSituacao({
															value: e.value.toString(),
															label: e.label,
														});
														setSituacaoDropdown(false);
													}}>
													<SelectItem
														active={situacao.value === e.value.toString()}>
														{e.label}
													</SelectItem>
												</Dropdown.Item>
											);
										})}
									</SelectMenu>
								</DropdownMenu>
							</Dropdown>

							<Dropdown ref={permissaoRef} show={permissaoDropdown}>
								<Dropdown.Toggle
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setPermissaoDropdown(!permissaoDropdown);
									}}
									style={{ padding: 0 }}
									className='dropdown-toggle-categoria'>
									<SearchSelector
										onClick={(e) => {
											e.preventDefault();
										}}
										pressed={permissaoDropdown}
										active={permissao.value !== ''}>
										{permissao.value === '' ? 'Permissão' : permissao.label}
										{permissao.value !== '' && (
											<img
												className='ExportArrowDown'
												alt=''
												src={ArrowDownWhite}
											/>
										)}
									</SearchSelector>
								</Dropdown.Toggle>
								<DropdownMenu
									style={{
										background: 'transparent',
										border: 0,
										marginTop: -10,
									}}>
									<SelectMenu>
										<Dropdown.Item
											style={{ padding: 0 }}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setPermissao({ value: '', label: 'Todos as empresas' });
												setPermissaoDropdown(false);
											}}>
											<SelectItem active={permissao.value === ''}>
												{'Todos'}
											</SelectItem>
										</Dropdown.Item>
										{tipos.map((e) => {
											return (
												<Dropdown.Item
													style={{ padding: 0 }}
													//className='dropdown-item-categoria'
													onClick={(a) => {
														a.preventDefault();
														a.stopPropagation();
														setPermissao({
															value: e.value.toString(),
															label: e.label,
														});
														setPermissaoDropdown(false);
													}}>
													<SelectItem
														active={permissao.value === e.value.toString()}>
														{e.label}
													</SelectItem>
												</Dropdown.Item>
											);
										})}
									</SelectMenu>
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>

					<TableContainer>
						<Table>
							<THead>
								<tr>
									<Th>Usuário</Th>
									<Th>Nome</Th>
									<Th>Permissão</Th>
									<Th>Situação</Th>
									<Th></Th>
								</tr>
							</THead>
							<TBody>
								{loading ? (
									<BodyTR>
										<td>
											<Skeleton style={{ width: '50%', height: 19 }} />{' '}
										</td>
										<td>
											<Skeleton style={{ width: '50%', height: 19 }} />{' '}
										</td>
										<td>
											<Skeleton style={{ width: '50%', height: 19 }} />{' '}
										</td>
										<td>
											<Skeleton style={{ width: '50%', height: 19 }} />{' '}
										</td>
										<td>
											<Skeleton style={{ width: '50%', height: 19 }} />{' '}
										</td>
									</BodyTR>
								) : (
									usuarios.map((usuario) => {
										return (
											<TRUser
												DeletarUsuario={DeletarUsuario}
												updateUsuario={updateUsuario}
												usuario={usuario}
											/>
										);
									})
								)}
							</TBody>
						</Table>
					</TableContainer>
				</Body>
			</Main>
		</Container>
	);
}

function TRUser({
	usuario,
	updateUsuario,
	DeletarUsuario,
}: {
	usuario: Usuario;
	updateUsuario(u: Usuario): void;
	DeletarUsuario(u: number): void;
}) {
	//const { usuario, situacao, permissao } = item;

	const history = useHistory();

	const [modalVisible, setModalVisible] = useState(false);

	async function RemoverUsuario() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.delete(`admin/api/user/${usuario.id}`, config);

			if (response.data.success) {
				DeletarUsuario(usuario.id);
			}
		} catch (error:any) {
			console.log(error);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	function getPermissao() {
		const tipos = [
			{ label: '', value: 0 },
			{ value: 999, label: 'Administrador' },
			{ value: 1, label: 'Consultor' },
		];

		const find = tipos.find(
			(t) => t.value.toString() === usuario.type.toString()
		);

		if (find) {
			return find.label;
		} else return 'Desconhecido';
	}

	const [deleteModal, setDeleteModal] = useState(false);

	return (
		<BodyTR>
			<td>{usuario.email ? usuario.email : '-'}</td>
			<td>{usuario.login}</td>
			<td>{getPermissao()}</td>
			<td>{usuario.status === 1 ? 'Ativa' : 'Inativa'}</td>

			<td>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginRight: 16,
						float: 'right',
					}}>
					<OverlayTrigger
						placement='bottom'
						overlay={
							<Tooltip id='tool-tip'>
								<span style={{ textTransform: 'capitalize' }}>
									{'Editar usuário'}
								</span>
							</Tooltip>
						}>
						<button
							type='button'
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								//setShowDropdown(!showDropdown);
								setModalVisible(true);
							}}
							style={{ marginRight: 8 }}
							className='tableItem_icon_container'>
							<img className='tableItem_icon_edit' src={EditGray} alt='e' />
						</button>
					</OverlayTrigger>

					<OverlayTrigger
						placement='bottom'
						overlay={
							<Tooltip id='tool-tip'>
								<span style={{ textTransform: 'capitalize' }}>
									{'Remover usuário'}
								</span>
							</Tooltip>
						}>
						<button
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setDeleteModal(true);
							}}
							className='tableItem_icon_container'>
							<img src={RemoveGray} alt='e' />
						</button>
					</OverlayTrigger>
				</div>

				<div
					style={{
						display: 'none',
						alignItems: 'center',
						float: 'right',
					}}>
					<ActionButton
						onClick={(e) => {
							e.preventDefault();
							setModalVisible(true);
							//setModalNovaEmpresa(true);
						}}>
						Editar
					</ActionButton>
					<ActionButton>Remover</ActionButton>
				</div>
			</td>
			<Modal
				show={modalVisible}
				onHide={() => {
					setModalVisible(false);
				}}>
				<Modal.Body>
					<ModalNovoUsuario
						usuario={usuario}
						setModalVisible={setModalVisible}
						updateUsuario={updateUsuario}
					/>
				</Modal.Body>
			</Modal>

			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<Modal.Body>
					<DeleteModal
						activeFunction={RemoverUsuario}
						labelStart='Tem certeza que deseja deletar o usuário '
						setModalVisible={setDeleteModal}
						focusItem={usuario.login}
						labelEnd={'? Essa ação não poderá ser desfeita.'}
						title={'Deletar Usuário?'}
					/>
				</Modal.Body>
			</Modal>
		</BodyTR>
	);
}

function useOutsideAlert(ref: any, handleClickOutside: any) {
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);
}
