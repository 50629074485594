/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Dashboard from './dashboard';
import Login from './login';
import Relatorios from './relatorios';
import PrivateRoute from './privateRoute';
import AppRoutes from './appRoutes';
//import Admin from './admin/admin';
//import Login from './login/login';

export default function Routes() {
	/**
     * 
				<Route path='/' exact component={Login} />
				<Route path='/admin' exact component={Admin} />
     */

	return (
		<BrowserRouter>
			<Switch>
				<Route path='/login' exact component={Login} />
				<PrivateRoute path='/' component={AppRoutes} />
				
			</Switch>
		</BrowserRouter>
	);
}
