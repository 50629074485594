/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { colors } from '../../globalStyles/colors';
import { Usuario } from '../../models/model';
import ClearIcon from '../../assets/images/clearIcon.svg';
import { InputContainer, SelectContainer } from '../../components/Input';
import api from '../../services/api';
import Loading from '../../assets/images/loading-roll.svg';

import * as yup from 'yup';

const Modal = styled.div`
	//padding-top: 25px;
	//padding-left: 50px;
	//padding-right: 20000px;
	//padding-bottom: 25px;
	min-height: 100vh;
	background-color: #f5f5f5;
	width: 50vw;
	//margin-left: 50vw;
	height: 100%;
	flex: 1;
	.mb-8 {
		margin-bottom: 8px;
	}

	.mb-32 {
		margin-bottom: 32px;
	}
	.mb-40 {
		margin-bottom: 40px;
	}
`;

const ModalHeader = styled.div`
	background: #f6f6f6 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000029;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-top: 21px;
	padding-bottom: 21px;

	padding-left: 30px;
	padding-right: 30px;
	height: 80px;
`;

const HeaderTitle = styled.h1`
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	display: flex;
	align-items: center;
`;

const HeaderButton = styled.button`
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	padding-left: 16px;
	padding-right: 16px;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	border: 0;
	.loading-roll {
		width: 21px;
		height: 21px;
	}
`;

const ModalContainer = styled.form`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: -16px 0px 16px #00000029;
	opacity: 1;
	display: flex;
	flex-direction: column;
	//flex: 1;
	height: calc(100vh - 80px);
	padding: 30px;
	padding-top: 0;
	.modal_item_container {
		max-width: 100%;
		width: 100%;
		margin-bottom: 24px;
	}

	.inputContainer {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9f9f9f;
		border-radius: 16px;
		height: 50px;
		display: flex;
		flex-direction: row;
		padding: 15px;
		input,
		select {
			::placeholder {
				font: normal normal 300 14px/19px Roboto;
				letter-spacing: 0px;
				color: #9f9f9f;
			}
			text-align: left;
			font: normal normal 500 14px/19px Roboto;
			letter-spacing: 0px;
			color: #222222;
			flex: 1;
			border: 0;
			margin-top: -15px;
			margin-bottom: -15px;
		}
	}
`;

const ModalLabel = styled.p`
	margin: 0;
	text-align: left;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #4b4b4b;
	text-transform: uppercase;
`;

interface ModalInputContainerProps {
	shadowColor?: string;
	borderColor?: string;
}
const ModalInputContainer = styled.div<ModalInputContainerProps>`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid
		${(props) => (props.borderColor ? props.borderColor : '#9f9f9f')};
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	height: 50px;
	box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-webkit-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-moz-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};

	width: 100%;
	.modal_input_icon_container {
		height: 100%;
		padding-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal_input_icon {
		width: 16px;
		height: 16px;
		border-radius: 8px;
		align-self: center;
		cursor: pointer;
	}

	.modal_input {
		border: transparent;
		border-radius: 16px;
		flex: 1;
		overflow: hidden;
		padding-left: 16px;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;

		::-webkit-input-placeholder {
			/* Edge */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		::placeholder {
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}
	}
`;

interface ModalProps {
	setModalVisible(b: boolean): void;
	usuario?: Usuario;
	updateUsuario(u: Usuario): void;
}

export default function ModalNovoUsuario({
	setModalVisible,
	updateUsuario,
	usuario,
}: //tamanho = 0,
ModalProps) {
	const [isError, setIsError] = useState<any>({});

	const [login, setLogin] = useState('');
	const [email, setEmail] = useState('');
	const [senha, setSenha] = useState('');
	const [permissao, setPermissao] = useState('');
	const [situacao, setSituacao] = useState('0');
	const [loading, setLoading] = useState(false);

	const [merchant, setMerchant] = useState('');

	const [listaMerchants, setListaMerchants] = useState([
		{
			label: '',
			value: '',
		},
	]);

	async function getListMerchant() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		try {
			const response = await api.get('/admin/api/merchant', config);

			if (response.data && response.data.merchants) {
				const r = response.data.merchants;
				const list = [
					{
						label: '',
						value: '',
					},
				];

				r.forEach((a: any) => {
					list.push({
						value: `${a.id}`,
						label: a.name,
					});
				});

				setListaMerchants([...list]);
			}
		} catch (error) {
			console.log(error);
		}
		// /admin/api/merchant
	}

	useEffect(() => {
		getListMerchant();
	}, []);

	useEffect(() => {
		if (usuario) {
			setLogin(usuario.login);
			//setSenha(usuario.senha);
			setPermissao(usuario.type.toString());
			setSituacao(usuario.status.toString());
			setEmail(usuario.email || '');
		}
	}, [usuario]);
	const tipos = [
		{ label: '', value: '' },
		{ value: '999', label: 'Administrador' },
		{ value: '1', label: 'Consultor' },
	];

	const situacoes = [
		{
			label: 'Ativa',
			value: '1',
		},
		{
			label: 'Inativa',
			value: '0',
		},
	];

	async function EditUsuario() {
		setIsError({});
		setLoading(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const data = {
				login,
				status: parseInt(situacao),
				type: parseInt(permissao),
				email,
				password: senha,
				merchant_id:
					permissao === '1' && merchant !== '' ? parseInt(merchant) : undefined,
			};

			const schema = yup.object().shape({
				login: yup.string().required(),
				status: yup.number().required(),
				type: yup.number().required(),
				email: yup.string(),
				password: yup.string(),
			});

			await schema.validate(data, { abortEarly: false });

			const response = await api.post(
				`admin/api/user${usuario ? `/${usuario.id}` : ''}`,
				data,
				config
			);

			if (response.data.user) {
				updateUsuario(response.data.user);
				setModalVisible(false);
			}
			setLoading(false);
		} catch (error: any) {
			console.log(error);
			const validationError = {} as any;
			if (error instanceof yup.ValidationError) {
				error.inner.forEach((err) => {
					//console.log(err.path);
					if (err.path) validationError[err.path] = true;
				});
			}
			setIsError(validationError);
			// console.log(validationError)
			setLoading(false);
		}
	}

	return (
		<Modal style={{ maxWidth: 500 }}>
			<ModalHeader>
				<HeaderTitle>{usuario ? 'Editar usuário' : 'Novo usuário'}</HeaderTitle>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<HeaderButton
						onClick={(e) => {
							e.preventDefault();

							setModalVisible(false);
						}}
						style={{
							background: 'transparent',
							color: '#676767',
							marginRight: 8,
						}}>
						Cancelar
					</HeaderButton>
					<HeaderButton
						onClick={(e) => {
							e.preventDefault();
							//EditEmpresa();
							EditUsuario();
						}}
						disabled={loading}
						style={{ background: '#28A745', color: '#FFFFFF' }}>
						{!loading ? (
							'Salvar'
						) : (
							<img className='loading-roll' alt='loading' src={Loading} />
						)}
					</HeaderButton>
				</div>
			</ModalHeader>

			<ModalContainer
				onSubmit={(e) => {
					e.preventDefault();
					EditUsuario();
				}}>
				<InputContainer
					isError={isError.login}
					fieldLabel={'Login'}
					inputName={login}
					setInputField={setLogin}
					disabled={usuario ? true : false}
					placeholder={'Insira o nome de usuário'}
				/>

				<InputContainer
					fieldLabel={'Email'}
					isError={isError.email}
					inputName={email}
					setInputField={setEmail}
					placeholder={'Insira o e-mail'}
				/>

				<InputContainer
					fieldLabel={'Senha'}
					inputName={senha}
					setInputField={setSenha}
					isError={isError.password}
					placeholder={'Insira a senha'}
				/>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					<div style={{ width: '47%' }}>
						<SelectContainer
							isError={isError.type}
							fieldLabel={'Permissão'}
							inputName={permissao}
							setInputField={setPermissao}
							options={tipos}
						/>
					</div>

					<div style={{ width: '47%' }}>
						<SelectContainer
							fieldLabel={'Situação'}
							inputName={situacao}
							setInputField={setSituacao}
							options={situacoes}
							isError={isError.status}
						/>
					</div>
				</div>
				{permissao === '1' && (
					<SelectContainer
						isError={isError.type}
						fieldLabel={'Empresa'}
						inputName={merchant}
						setInputField={setMerchant}
						options={listaMerchants}
					/>
				)}
			</ModalContainer>
		</Modal>
	);
}
