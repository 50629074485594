import styled from 'styled-components';
import { colors } from '../../globalStyles/colors';

export const Container = styled.div`
	background: #f5f5f5;
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	padding: 30px;
	padding-left: 16px;
	//flex-direction: row;
	//max-width:100vw;
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	height: 80px;
	//flex: 1;
	align-items: center;
	padding-left: 20px;
	//padding-right: 20px;
	padding-top: 24px;
	padding-bottom: 24px;

	border: solid;
	border-top: 0;
	border-left: 0;
	border-right: 0;

	border-bottom-width: 1px;
	border-color: #e5e5e5;

	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 24px;

	position: relative;

	margin-bottom: 16px;
	//background:#000
	//max-width:100vw;

	.dropdown-menu {
		min-width: 208px;
		background: transparent 0% 0% no-repeat padding-box;
		border: 0;
		padding: 0;

		.dropdown-item {
			text-align: left;
			font: normal normal normal 14px/19px Roboto;
			letter-spacing: 0px;
			color: #222222;

			background: #ffffff 0% 0% no-repeat padding-box;

			:hover {
				background: rgba(255, 255, 255, 0.3) 0% 0% no-repeat padding-box;
			}
		}
	}

	justify-content: space-between;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 80px;
	align-items: center;
`;

export const HeaderExport = styled.div`
	background: #18aa36 0% 0% no-repeat padding-box;
	border-radius: 15px;
	height: 30px;
	width: 130px;
	//text-align: center;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 16px;
	//padding-right: 8px;

	.ExportDropdown {
		font: normal normal normal 12px/16px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		text-transform: uppercase;
		opacity: 1;
		vertical-align: center;
		// text-align: left;
		width: 100%;
		img {
			width: 16px;
			height: 16px;
			justify-self: center;
			align-self: center;
			text-align: center;
		}
	}
	.ExportArrowDown {
		//flex:1;
		border-radius: 0px 15px 15px 0px;
		height: 100%;

		:hover {
			background-color: #12842a;
		}
	}
`;

export const HeaderTitle = styled.p`
	text-align: left;
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	text-transform: capitalize;
	margin-right: 23px;
`;

interface HeaderButtonProps {
	active: boolean;
}

export const HeaderButton = styled.button<HeaderButtonProps>`
	cursor: pointer;
	background: ${(props) => (props.active ? '#18AA36' : '#F2F2F2')};
	//border: ${(props) => (props.active ? '1px solid #09aa36' : 0)};
	border: 0;
	box-shadow: ${(props) => (props.active ? '0px 0px 8px #00000029' : 0)};
	border-radius: 15px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 16px;
	padding-left: 16px;
	margin-right: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;

	p {
		text-align: center;
		font: normal normal normal 12px/16px Roboto;
		letter-spacing: 0px;
		color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};
		text-transform: uppercase;
		margin: 0;
	}

	.MenuPersonalizadoSvgContainer {
		background: ${(props) =>
			props.active
				? '#0D5E1E 0% 0% no-repeat padding-box'
				: 'transparent 0% 0% no-repeat padding-box'};
		margin-right: 3px;
		width: 44px;
		height: 29px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 15px;
		margin-top: -6px;
		margin-bottom: -6px;
		margin-left: -16px;
	}

	svg {
		color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};
		width: 13px;
		height: 13px;
	}
`;

export const HeaderUserContainer = styled.div`
	position: absolute;
	right: 20px;

	height: 40px;

	.dropdown-toggle-categoria {
		padding: 0;
	}

	.header_user_flex {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.user_circle {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #18aa36;
		opacity: 1;
		height: 40px;
		width: 40px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #18aa36;
		text-transform: uppercase;
		vertical-align: center;
	}
`;

interface HeaderSearchContainerProps {
	active: boolean;
}

export const HeaderSearchContainer = styled.form<HeaderSearchContainerProps>`
	height: 40px;
	display: flex;
	flex-direction: row;

	background: #f0f0f0 0% 0% no-repeat padding-box;
	border-radius: 20px;
	border: 0;
	opacity: 1;
	align-items: center;
	min-width: 230px;

	margin-right: 24px;

	input {
		//display: ${(props) => !props.active && 'none'};
		transition: all 1s;
		width: ${(props) => (!props.active ? 0 : '150px')};
		// margin-left: ${(props) => (!props.active ? 0 : '16px')};
		padding: ${(props) => (!props.active ? 0 : '11px')};
	}

	button {
		cursor: pointer;
		padding: 11px;
		background: transparent;
		border: 0;
		display: flex;
		align-items: center;
	}

	svg {
		color: #09aa36;
		width: 16px;
		height: 16px;
	}
`;

export const HeaderSearchInput = styled.input`
	text-align: left;
	font: normal normal normal 14px/19px Roboto;
	letter-spacing: 0px;
	color: #9f9f9f;
	opacity: 1;
	flex: 1;
	border: 0;
	background-color: transparent;
	//padding:11px;
`;

export const Body = styled.div`
	padding-top: 20px;
	padding-bottom: 0;
	flex: 1;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 24px;
	//background:#000;
`;

export const RelatoriosSelectContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	//justify-content: space-between;

	.scrollable-dropdown {
		::-webkit-scrollbar {
			background: transparent;

			// display:none;
			border-radius: 18px;

			width: 12px;
		}

		&::-webkit-scrollbar-track {
			/*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
			// margin-top: 54px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: rgba(103, 103, 103, 0.5);
			border-radius: 20px;
			border: 4px solid rgba(0, 0, 0, 0);
			// border-right: 5px solid rgba(0, 0, 0, 0);

			background-clip: padding-box;
			max-height: 5px;
			transition: all 1s;
		}
	}
`;

export const SearchContainer = styled.div`
	display: flex;
	flex-direction: row;

	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #09aa36;
	border-radius: 10px;
	opacity: 1;
	align-items: center;
`;

interface SearchSelectorProps {
	active?: boolean;
	pressed?: boolean;
}
export const SearchSelector = styled.button<SearchSelectorProps>`
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
	margin-right: 10px;

	background: ${(props) => (props.active ? '#18AA36' : '#F0F0F0')} 0% 0%
		no-repeat padding-box;
	border-radius: 20px;

	text-align: center;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};
	text-transform: uppercase;
	cursor: pointer;
	border: ${(props) =>
		props.pressed ? '1px solid #EBEBEB' : '1px solid transparent'};

	box-shadow: ${(props) => (props.pressed ? 'inset 0px 3px 6px #00000029' : 0)};
`;

export const SelectMenu = styled.div`
	background: #f6f6f8 0% 0% no-repeat padding-box;
	box-shadow: 0px 6px 12px #0000001a;
	border: 1px solid #ebebeb;
	border-radius: 18px;
	padding: 10px;
	padding-bottom: 2px;
	margin-top: 6px;

	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 200px;

	::-webkit-scrollbar {
			background: transparent;

			// display:none;
			border-radius: 18px;

			width: 0;
		}

	.dropdown-item {
		:hover {
			background: transparent;
		}
	}
`;

export const SelectItem = styled.div<SearchSelectorProps>`
	background: ${(props) => (props.active ? '#18aa36' : 'transparent')} 0% 0%
		no-repeat padding-box;
	// background: #FFFFFF 0% 0% no-repeat padding-box;

	border-radius: 18px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	padding-left: 15px;
	//justify-content: center;
	height: 35px;
	width: 150px;

	text-align: left;
	font: normal normal ${(props) => (props.active ? 500 : 'normal')} 14px/19px
		Roboto;
	letter-spacing: 0px;
	color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};

	:hover {
		box-shadow: 0px 6px 12px #22222226;
		background: ${(props) => (props.active ? '#18aa36' : '#FFFFFF')} 0% 0%
			no-repeat padding-box;
		font: normal normal 500 14px/19px Roboto;
	}
`;

export const RelatoriosSelectBlock = styled.div`
	border: solid;
	margin: 0;
	border-top: 0;
	border-right: 0;
	border-left: 0;

	border-bottom-width: 1px;
	border-bottom-color: #09aa36;
	padding-left: 7px;
	padding-right: 7px;
	margin-right: 15px;
`;

export const RelatorioBlockTitle = styled.p`
	margin: 0;
	text-align: left;
	font: normal normal normal 12px/16px Roboto;
	letter-spacing: 0px;
	color: #676767;
	opacity: 1;
`;

export const RelatorioSelect = styled.select`
	background: transparent;
	border: 0;
	cursor: pointer;

	text-align: left;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: #09aa36;

	width: 150px;

	option {
		color: ${colors.strongFont};
	}
`;
export const DropDownContainer = styled.div`
	width: 150px;
`;

export const DropDownHeader = styled.div`
	cursor: pointer;
`;

export const LimparFiltrosButton = styled.button`
	cursor: pointer;
	border: 0;
	background: #09aa36 0% 0% no-repeat padding-box;
	border-radius: 10px;
	opacity: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 40px;
	// vertical-align: bottom;
	padding-right: 10px;
	padding-left: 10px;

	svg {
		color: #ffffff;
		width: 16px;
		height: 16px;
		margin-right: 8px;
		vertical-align: middle;
	}
	p {
		margin: 0;
		text-align: center;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 1;
	}
`;

export const TableFooter = styled.div`
	position: sticky;
	position: -webkit-sticky;
	background: #f9f9f9 0% 0% no-repeat padding-box;
	border-radius: 18px;
	height: 36px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	//z-index: 20000;
	padding-left: 16px;
	padding-right: 11px;
	p {
		margin: 0;
	}

	.table_footer_label {
		text-align: left;
		font: normal normal normal 12px/16px Roboto;
		letter-spacing: 0px;
		color: #222222;
	}
	.table_footer_label_strong {
		font: normal normal bold 12px/16px Roboto;
	}

	.table_footer_input {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #d3d3d3;
		border-radius: 12px;
		height: 24px;
		width: 48px;
		text-align: center;
		font: normal normal bold 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;
		margin-right: 9px;
		margin-left: 9px;
		outline: none;
	}
	.table_footer_border_right {
		border-right: 1px solid #d3d3d3;
	}

	.table_footer_padding_right-20 {
		padding-right: 20px;
	}
	.table_footer_margin_left-20 {
		margin-left: 20px;
	}

	.table_footer_border_right-12 {
		border-top-right-radius: 12px;
		border-bottom-right-radius: 12px;
	}

	.table_footer_border_left-12 {
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
	}

	.table_footer_button_container {
		background: #18aa36 0% 0% no-repeat padding-box;
		height: 24px;
		width: 70px;
		display: flex;
		flex-direction: row;

		img {
			width: 16px;
			height: 16px;
		}
	}

	.table_footer_button {
		flex: 1;
		:hover {
			background: #12842a 0% 0% no-repeat padding-box;
		}
		color: #ffffff;
		border: 0;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const TableContainer = styled.div`
	margin-top: 20px;
	height: calc(100vh - 25px - 64px - 25px - 40px - 20px - 65px - 55px);

	overflow-y: scroll;

	overflow-x: hidden;
	// padding-bottom: 40px;

	::-webkit-scrollbar {
		width: 0px;
	}
`;

export const Table = styled.table`
	border: 0;
	width: 100%;
	border-spacing: 0px 0px;
	border-radius: 10px;
`;

export const THead = styled.thead`
	//background: #09aa36 0% 0% no-repeat padding-box;
	//border-radius: 100px;
	border: 0;
	width: 100%;

	tr {
		//border-radius: 100px;
		height: 40px;
		//background-color:#000
	}

	th {
		//border: solid 1px #000;
		//border-style: none none;
		padding: 10px;
		//padding: 9=;
		//background-color: cyan;
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 0;
		background: #f4f4f4 0% 0% no-repeat padding-box;
		:first-child {
			border-left-style: solid;
			//border-top-left-radius: 10px;
			//border-bottom-left-radius: 10px;
			padding-left: 24px;
		}
		:last-child {
			border-right-style: solid;
			//border-bottom-right-radius: 10px;
			//border-top-right-radius: 10px;
		}
	}
`;

interface Thprops {
	active?: boolean;
}

export const Th = styled.th<Thprops>`
	text-align: left;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: #676767;
	text-transform: uppercase;
	border: 0;
	background-color: transparent;
	//border-radius:10px;

	border-left: ${(props) => (props.active ? '8px solid #f4f4f4' : '')};
`;

export const TBody = styled.tbody``;

interface BodyTRprops {
	active?: boolean;
}

export const BodyTR = styled.tr<BodyTRprops>`
	padding-top: 22px;
	padding-bottom: 22px;
	padding-right: 20px;
	padding-left: 20px;
	background-color: #ffffff;

	:hover {
		background: #f7fcf8 0% 0% no-repeat padding-box;
	}

	td {
		//border: solid 1px #000;
		//border-style: none none;
		padding-left: 10px;
		padding-right: 10px;
		//background-color: cyan;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #676767;
		height: 60px;

		:first-child {
			//border-left-style: solid;
			/*border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;*/
			padding-left: 24px;
			border-left: 8px ${(props) => (props.active ? '#18AA36' : 'transparent')}
				solid;

			:hover {
				border-left: 8px ${(props) => (props.active ? '#18AA36' : '#f7fcf8')}
					solid;
			}
		}
		:last-child {
			//border-right-style: solid;
			/*border-bottom-right-radius: 10px;
			border-top-right-radius: 10px;*/
		}
	}
`;

export const Detalhes = styled.button`
	cursor: pointer;
	background: #ffffff 0% 0% no-repeat padding-box;
	//border: 1px solid #09aa36;
	//border-radius: 10px;

	padding-left: 16px;
	padding-right: 16px;
	padding-top: 8px;
	padding-bottom: 8px;

	text-align: center;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: #09aa36;
	border: 0;
`;

export const Gradient = styled.div`
	position: sticky;
	bottom: 0;

	background: transparent linear-gradient(180deg, #f5f5f500 0%, #f5f5f5 100%) 0%
		0% no-repeat padding-box;
	margin-top: -32px;
	height: 32px;
	width: 100%;
`;

export const Skeleton = styled.div`
	background-image: linear-gradient(
		-90deg,
		#fafafc 0%,
		#e4e4e4 50%,
		#fafafc 100%
	);
	background-size: 400% 400%;
	animation: shimmer 1.2s ease-in-out infinite;
	@keyframes shimmer {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -135% 0%;
		}
	}
`;

export const SkeletonGreen = styled.div`
	background-image: linear-gradient(
		-90deg,
		var(--primaryColorDark) 0%,
		#abdb30 50%,
		var(--primaryColorDark) 100%
	);
	background-size: 400% 400%;
	animation: shimmer 1.2s ease-in-out infinite;
	@keyframes shimmer {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -135% 0%;
		}
	}
`;

export const ExportHeaderMenu = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 16px;
	overflow: hidden;

	.export_header_menu_title {
		background: #f8f8f8 0% 0% no-repeat padding-box;
		border: 1px solid #dee2e6;
		border-radius: 16px 16px 0px 0px;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;
		padding-top: 8px;
		padding-bottom: 10px;
		padding-left: 20px;
	}
	.dropdown-item {
		text-align: left;
		font: normal normal normal 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;
		padding-top: 8px;
		padding-bottom: 10px;
		padding-left: 20px;
	}
`;
