/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import { Switch } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import Dashboard from './dashboard';
import Relatorios from './relatorios';

import styled from 'styled-components';
import MenuContainer from '../components/Menu';
import ModalScreen from '../components/Modal';
import { connect } from 'react-redux';
import { InitialStateProps } from '../store/index';
import Categorias from './categorias';
import Correspondentes from './correspondentes';
import Usuarios from './usuarios';
import Configuracoes from './configuracoes';
import api from '../services/api';
import { useState } from 'react';
import Conciliacoes from './conciliações';

const Container = styled.div`
	max-width: 100vw;
	min-height: 100vh;
	background: #f5f5f5;
	display: flex;
	flex-direction: row;
	padding-left: 30px;
	//padding-right: 30px;
`;

interface LoginProps {
	email: string | null;
	login: string;
	merchant: string | null;
	type: number;
}
export default function AppRoutes() {
	const [login, setLogin] = useState<LoginProps | undefined>();

	async function getUserData() {
		const token = sessionStorage.getItem('@bill-token');
		const localToken = localStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token || localToken}`,
			},
		};

		try {
			const response = await api.get('/admin/api/current', config);
			console.log(response.data);
			if (response.data && response.data.user) {
				setLogin(response.data.user);

				localStorage.setItem('@bill-login', JSON.stringify(response.data.user));
			}
		} catch (error: any) {
			console.error(error);
		}
	}

	useEffect(() => {
		getUserData();
	}, []);

	return (
		<Container>
			<MenuContainer login={login} />

			<ModalScreen />

			<Switch>
				<PrivateRoute exact key='dashboard' path='/' component={Dashboard} />
				<PrivateRoute
					exact
					key='relatorios'
					path='/relatorios'
					component={Relatorios}
				/>
				<PrivateRoute
					exact
					key='categorias'
					path='/categorias'
					component={Categorias}
				/>
				<PrivateRoute
					exact
					key='correspondentes'
					path='/correspondentes'
					component={Correspondentes}
				/>
				<PrivateRoute
					exact
					key='usuarios'
					path='/usuarios'
					component={Usuarios}
				/>
				<PrivateRoute
					exact
					key='configuracoes'
					path='/configuracoes'
					component={Configuracoes}
				/>
				<PrivateRoute
					exact
					key='conciliacoes'
					path='/conciliacoes'
					component={Conciliacoes}
				/>
			</Switch>
		</Container>
	);
}
